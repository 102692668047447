import React from 'react'
import { FaGithub, FaLinkedin, FaFacebook } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
const SocialLinks = () => {
  const links=[
    {
    id:1,
    child: (
      <>
      LinkedIn<FaLinkedin size={30}/>
      </>
    ),
    href: 'https://www.linkedin.com/in/satyam-kumar-singh-70474025b/',
    style: 'rounded-tr-md'
  },
  {
    id: 2,
    child: (
      <>
      Github<FaGithub size={30}/>
      </>
    ),
    href:"https://github.com/satyamkumarsingh23",
  },
  {
    id: 3,
    child: (
      <>
      Facebook<FaFacebook size={30}/>
      </>
    ),
    href:"https://www.facebook.com/profile.php?id=100012548730709",
  },
  {
    
    id: 4,
    child: (
      <>
      Mail<HiOutlineMail size={30}/>
      </>
    ),
    href:"mailto:satyamcngh23@gmail.com",
  },
  {
    id: 5,
    child: (
        <>
          Resume <BsFillPersonLinesFill size={30} />
        </>
      ),
      style: "rounded-br-md",
      href: "/SATYAM KUMAR SINGH.PDF",
      download: true,
  }
  ]
  return (
 <div className="hidden flex-col top-[30%] left-0 fixed lg:flex">
      <ul>
        {links.map((link) => (
          <li
            key={link.id}
            className={
              "flex justify-between items-center w-40 h-14 px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 bg-orange-500" +
              " " +
              link.style
            }
          >
            <a
              href={link.href}
              className="flex justify-between items-center w-full text-white"
              target="_blank"
              rel="noreferrer"
            >
              {link.child}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SocialLinks